import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'config';

interface Year {
    id: string;
    yearTarget: number;
    yearName: string;
    dateStart: string;
    dateEnd: string;
}

export const calendarApi = createApi({
    reducerPath: 'calendarApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
    }),
    endpoints: (builder) => ({
        getYearList: builder.query<{ valueList: Year[] }, { pageSize: number } | void>({
            query(config = { pageSize: 10 }) {
                return {
                    url: '/Year/list',
                    method: 'POST',
                    body: config,
                };
            },
        }),
    }),
});

export const { useGetYearListQuery } = calendarApi;
