import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'config';

import { Contact } from '@invivo-capital/accessors';

import { CompleteInfoCommitment, Member } from './constants/MemberDict';

export interface memberData {
    memberId: string;
    legalName: string;
}

interface CommitmentFileUploadInfo {
    name: string;
    fileExtension: number;
    size: number;
    memberid: string;
    yearid: string;
}

export interface Period {
    id: string;
    yearTarget: number;
    yearName: string;
    dateStart: string;
    dateEnd: string;
}

export const memberApi = createApi({
    reducerPath: 'member',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
    }),
    endpoints: (builder) => ({
        getMemberData: builder.query<{ payload: Member; positionList: string[] }, string>({
            query(memberId) {
                return {
                    url: '/Member?memberid=' + memberId,
                    method: 'GET',
                };
            },
        }),
        getMemberGroups: builder.query<any, { memberId: string; parentId: string | undefined }>({
            query(body) {
                return {
                    url: '/Member/groups?currentmemberid=' + body.memberId + '&parentid=' + body.parentId,
                    method: 'GET',
                };
            },
        }),
        getCommitmentInfo: builder.query<
            { payload: CompleteInfoCommitment },
            { memberId: string; yearid: string | undefined }
        >({
            query({ memberId, yearid }) {
                return {
                    url: '/File/commitmentDocuments/completeinfo?memberid=' + memberId + '&yearid=' + yearid,
                    method: 'GET',
                };
            },
        }),
        generateUniqueDocument: builder.mutation<
            { payload: CompleteInfoCommitment },
            { memberid: string; yearid: string | undefined }
        >({
            query(body) {
                return {
                    url: `/File/commitmentDocuments/uniquedoc?yearid=${body.yearid}&memberid=${body.memberid}`,
                    method: 'POST',
                };
            },
        }),
        getSignedDocUploadLink: builder.mutation<{ sas: string; fileInfo: { id: string } }, CommitmentFileUploadInfo>({
            query(body) {
                return {
                    url: `/File/commitmentDocuments/signdoc?yearid=${body.yearid}&memberid=${body.memberid}`,
                    method: 'POST',
                    body: {
                        ...body,
                        memberId: body.memberid,
                    },
                };
            },
        }),
        getSignedDocDownloadLink: builder.mutation<{ sas: string; fileInfo: { id: string } }, CommitmentFileUploadInfo>(
            {
                query(body) {
                    return {
                        url: `/File/commitmentDocuments/signdoc?yearid=${body.yearid}&memberid=${body.memberid}`,
                        method: 'POST',
                        body: {
                            ...body,
                            memberId: body.memberid,
                        },
                    };
                },
            },
        ),
        // contacts crud
        contactCreate: builder.mutation<{ payload: Contact }, Partial<Contact>>({
            query(contact) {
                return {
                    url: '/Member/contact',
                    method: 'POST',
                    body: contact,
                };
            },
        }),
        contactUpdate: builder.mutation<{ payload: Contact }, Partial<Contact>>({
            query(contact) {
                return {
                    url: '/Member/contact',
                    method: 'PATCH',
                    body: contact,
                };
            },
        }),
        contactDelete: builder.mutation<{ payload: Contact }, Contact>({
            query(contact) {
                return {
                    url: '/Member/contact?contactid=' + contact.id,
                    method: 'DELETE',
                };
            },
        }),
    }),
});

export const {
    useGetMemberDataQuery,
    useGetMemberGroupsQuery,
    useGetCommitmentInfoQuery,
    useGenerateUniqueDocumentMutation,
    useGetSignedDocUploadLinkMutation,
    useGetSignedDocDownloadLinkMutation,
    useContactCreateMutation,
    useContactUpdateMutation,
    useContactDeleteMutation,
} = memberApi;
