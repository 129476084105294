import { configureStore } from '@reduxjs/toolkit';

import {
    commonApi,
    declarationApi,
    fileApi,
    membersApi,
    outgoingEventsApi,
    rtkQueryErrorLogger,
} from '@invivo-capital/accessors';

import { calendarApi } from 'modules/Board/components/Calendar/api';
import { memberApi } from 'modules/MemberInfo/api';

import { authApi } from '../../Login/api';

import { memberReducer } from './member';

export const store = configureStore({
    reducer: {
        [fileApi.reducerPath]: fileApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [calendarApi.reducerPath]: calendarApi.reducer,
        [memberApi.reducerPath]: memberApi.reducer,
        [commonApi.reducerPath]: commonApi.reducer,
        [declarationApi.reducerPath]: declarationApi.reducer,
        [membersApi.reducerPath]: membersApi.reducer,
        [outgoingEventsApi.reducerPath]: outgoingEventsApi.reducer,
        memberId: memberReducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware().concat(
            authApi.middleware,
            fileApi.middleware,
            calendarApi.middleware,
            memberApi.middleware,
            commonApi.middleware,
            declarationApi.middleware,
            membersApi.middleware,
            outgoingEventsApi.middleware,
            rtkQueryErrorLogger,
        ),
    ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
