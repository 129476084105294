import { createSlice } from '@reduxjs/toolkit';

export const storageMemberIdKey = 'lsMemberId';

const memberIdSlice = createSlice({
    name: 'MemberId',
    initialState: '',
    reducers: {
        changeMember(state, action) {
            window.sessionStorage.setItem(storageMemberIdKey, action.payload);

            return action.payload;
        },
    },
});

const { actions, reducer } = memberIdSlice;

export const { changeMember } = actions;
export { reducer as memberReducer };
