import 'antd/dist/antd.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

import { useWebserviceAuthenticateMutation } from '@invivo-capital/accessors';
import { AuthProvider, AuthTemplate } from '@invivo-capital/components';
import { GlobalStyle, SplashScreen, defaultTheme } from '@invivo-capital/ui-kit';

import { store } from './slices/store';

const AppRoutes = React.lazy(() => import('./pages'));

export const App: React.FC = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyle />
            <AuthProvider>
                <Provider store={store}>
                    <Router>
                        <AuthTemplate useAuthenticateMutation={useWebserviceAuthenticateMutation}>
                            <React.Suspense fallback={<SplashScreen.Loading tip="Loading..." />}>
                                <AppRoutes />
                            </React.Suspense>
                        </AuthTemplate>
                        <ToastContainer autoClose={false} draggable={false} toastStyle={{ wordBreak: 'break-all' }} />
                    </Router>
                </Provider>
            </AuthProvider>
        </ThemeProvider>
    );
};
