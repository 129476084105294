import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import isBetween from 'dayjs/plugin/isBetween';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from 'modules/app';
import { suppressFindDOMNodeError } from 'modules/common/utils/errors';

import { reportWebVitals } from './reportWebVitals';

const isDevEnvironment = process.env.NODE_ENV === 'development';

dayjs.extend(isBetween);
dayjs.locale('fr');

if (isDevEnvironment) {
    // Uncomment to enable mocks. Mock server breaks uploader progress.
    // import('utils/mirage').then(({ makeServer }) => makeServer());
    suppressFindDOMNodeError();
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals(console.debug);
