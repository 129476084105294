import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL } from 'config';

export interface LoginData {
    email: string;
    password: string;
}

export const authApi = createApi({
    reducerPath: 'userAuth',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
    }),
    endpoints: (builder) => ({
        login: builder.mutation<{ token: string }, LoginData>({
            query(userData) {
                return {
                    url: '/User/login',
                    method: 'POST',
                    body: userData,
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                    },
                };
            },
        }),
    }),
});

export const { useLoginMutation } = authApi;
